import { default as _91token_93M3Ls3T9sdvMeta } from "/var/www/sites/auth.staging.ultrafedt.dk/releases/20241213100520/pages/activate/[token].vue?macro=true";
import { default as index3SxdrYNDkRMeta } from "/var/www/sites/auth.staging.ultrafedt.dk/releases/20241213100520/pages/index.vue?macro=true";
import { default as _91token_93eaOo0c9YCeMeta } from "/var/www/sites/auth.staging.ultrafedt.dk/releases/20241213100520/pages/password/reset/[token].vue?macro=true";
import { default as indexHjvFRuXy6bMeta } from "/var/www/sites/auth.staging.ultrafedt.dk/releases/20241213100520/pages/password/reset/index.vue?macro=true";
import { default as redirectVvCUzvmS3AMeta } from "/var/www/sites/auth.staging.ultrafedt.dk/releases/20241213100520/pages/redirect.vue?macro=true";
export default [
  {
    name: "activate-token",
    path: "/activate/:token()",
    component: () => import("/var/www/sites/auth.staging.ultrafedt.dk/releases/20241213100520/pages/activate/[token].vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/sites/auth.staging.ultrafedt.dk/releases/20241213100520/pages/index.vue")
  },
  {
    name: "password-reset-token",
    path: "/password/reset/:token()",
    component: () => import("/var/www/sites/auth.staging.ultrafedt.dk/releases/20241213100520/pages/password/reset/[token].vue")
  },
  {
    name: "password-reset",
    path: "/password/reset",
    component: () => import("/var/www/sites/auth.staging.ultrafedt.dk/releases/20241213100520/pages/password/reset/index.vue")
  },
  {
    name: "redirect",
    path: "/redirect",
    component: () => import("/var/www/sites/auth.staging.ultrafedt.dk/releases/20241213100520/pages/redirect.vue")
  }
]